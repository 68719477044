import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoadingAnimation from "./components/LoadingAnimation/LoadingAnimation";
// Daha performanslı çalışması için React.lazy
const HomePage = React.lazy(() => import("./views/Homepage/Homepage"));
const Report = React.lazy(() => import("./views/Report/Report"));
const Reservation = React.lazy(() => import("./views/Reservation/Reservation"));
const Income = React.lazy(() => import("./views/Income/Income"));
const Expenses = React.lazy(() => import("./views/Expenses/Expenses"));
const Yachts = React.lazy(() => import("./views/Yachts/Yachts"));
const IncomeCategories = React.lazy(() =>
  import("./views/IncomeCategories/IncomeCategories")
);
const ExpenseCategories = React.lazy(() =>
  import("./views/ExpenseCategories/ExpenseCategories")
);
const SalesChannel = React.lazy(() =>
  import("./views/SalesChannel/SalesChannel")
);
const TourProgram = React.lazy(() => import("./views/TourProgram/TourProgram"));
const Menu = React.lazy(() => import("./views/Menu/Menu"));
const Agency = React.lazy(() => import("./views/Agency/Agency"));
const Guest = React.lazy(() => import("./views/Guest/Guest"));
const MyAccount = React.lazy(() => import("./views/MyAccount/MyAccount"));
const SuperAdmin = React.lazy(() => import("./views/SuperAdmin/SuperAdmin"));
const Notification = React.lazy(() =>
  import("./views/Notification/Notification")
);
const IzForm = React.lazy(() => import("./views/IzForm/IzForm"));
const Pay = React.lazy(() => import("./views/Pay/Pay"));
const SupportPage = React.lazy(() => import("./views/SupportPage/SupportPage"));

const App = () => {
  return (
    <Suspense fallback={""}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/pay" element={<Pay />} />
        <Route path="/izForm" element={<IzForm />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/income" element={<Income />} />
        <Route path="/expenses" element={<Expenses />} />
        <Route path="/report" element={<Report />} />
        <Route path="/yachts" element={<Yachts />} />
        <Route path="/sales-channel" element={<SalesChannel />} />
        <Route path="/income-categories" element={<IncomeCategories />} />
        <Route path="/expense-categories" element={<ExpenseCategories />} />
        <Route path="/tour-program" element={<TourProgram />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/agency" element={<Agency />} />
        <Route path="/guest" element={<Guest />} />
        <Route path="/user-activities" element={<Notification />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/super-admin" element={<SuperAdmin />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default App;
