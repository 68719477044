const apiUrl = window.location.href.includes("staging")
  ? "https://staging-api.marinease.com/"
  : "https://api.marinease.com/";

const __apiUrl = "https://staging-api.marinease.com/";

import { createStyles } from "antd-style";

const isMobile = window.matchMedia("(max-width: 768px)").matches;
const isTablet = window.matchMedia(
  "(min-width: 769px) and (max-width: 1024px)"
).matches;

// Text length limit
const maxLength = 30;

// email Input max length
const emailMaxLength = 40;

// Global date format
const dateFormat = "DD/MM/YYYY";

// Global date format
const calendardateFormat = "DD/MM/YYYY HH:mm";

// Static Calendar for agency
const bigCaldateFormat = "DD/MM/YYYY HH:mm";

// Default country phone code
const countryPhoneCode = "+90";

// Other Agency color code
const otherAgency = "#c3c3c3";

// Maksimum upload toplam dosya boyutu (tek seferde)
const MAX_TOTAL_SIZE_MB = 20;
// Maksimum upload toplam yükleme adedi (tek seferde)
const maxCount = 10;

// Text area max length
const textareaMaxLength = 2500;

const chartColor = [
  "#f1c464",
  "#8f5fe2",
  "#e25fb2",
  "#b2e25f",
  "#57A4C3",
  "#2BACAD",
  "#3DB08B",
  "#6AAF66",
  "#96A949",
  "#BF9E40",
  "#E1904F",
  "#E17573",
  "#D97998",
  "#BC86B6",
  "#8E97C6",
  "#57A4C3",
  "#2BACAD",
  "#3DB08B",
  "#6AAF66",
  "#96A949",
  "#BF9E40",
  "#E1904F",
  "#f1c464",
  "#8f5fe2",
  "#e25fb2",
  "#b2e25f",
  "#57A4C3",
  "#2BACAD",
  "#3DB08B",
  "#6AAF66",
  "#96A949",
  "#BF9E40",
  "#E1904F",
  "#E17573",
  "#D97998",
  "#BC86B6",
  "#8E97C6",
  "#57A4C3",
  "#2BACAD",
  "#3DB08B",
  "#6AAF66",
  "#96A949",
  "#BF9E40",
  "#E1904F",
  "#f1c464",
  "#8f5fe2",
  "#e25fb2",
  "#b2e25f",
  "#57A4C3",
  "#2BACAD",
  "#3DB08B",
  "#6AAF66",
  "#96A949",
  "#BF9E40",
  "#E1904F",
  "#E17573",
  "#D97998",
  "#BC86B6",
  "#8E97C6",
  "#57A4C3",
  "#2BACAD",
  "#3DB08B",
  "#6AAF66",
  "#96A949",
  "#BF9E40",
  "#E1904F",
  "#f1c464",
  "#8f5fe2",
  "#e25fb2",
  "#b2e25f",
  "#57A4C3",
  "#2BACAD",
  "#3DB08B",
  "#6AAF66",
  "#96A949",
  "#BF9E40",
  "#E1904F",
  "#E17573",
  "#D97998",
  "#BC86B6",
  "#8E97C6",
  "#57A4C3",
  "#2BACAD",
  "#3DB08B",
  "#6AAF66",
  "#96A949",
  "#BF9E40",
  "#E1904F",
  "#f1c464",
  "#8f5fe2",
  "#e25fb2",
  "#b2e25f",
  "#57A4C3",
  "#2BACAD",
  "#3DB08B",
  "#6AAF66",
  "#96A949",
  "#BF9E40",
  "#E1904F",
  "#E17573",
  "#D97998",
  "#BC86B6",
  "#8E97C6",
  "#57A4C3",
  "#2BACAD",
  "#3DB08B",
  "#6AAF66",
  "#96A949",
  "#BF9E40",
  "#E1904F",
  "#f1c464",
  "#8f5fe2",
  "#e25fb2",
  "#b2e25f",
  "#57A4C3",
  "#2BACAD",
  "#3DB08B",
  "#6AAF66",
  "#96A949",
  "#BF9E40",
  "#E1904F",
  "#E17573",
  "#D97998",
  "#BC86B6",
  "#8E97C6",
  "#57A4C3",
  "#2BACAD",
  "#3DB08B",
  "#6AAF66",
  "#96A949",
];

// Global table style
const useStyle = createStyles(({ css, token }) => {
  const { antCls } = token;
  return {
    customTable: css`
      ${antCls}-table {
        ${antCls}-table-container {
          ${antCls}-table-body,
          ${antCls}-table-content {
            scrollbar-width: thin;
            scrollbar-color: #eaeaea transparent;
            scrollbar-gutter: stable;
          }
        }
      }
    `,
  };
});

// const selectConfig = (className) => {
//   return {
//     onDropdownVisibleChange: (open) => {
//       if (isMobile) {
//         if (open) {
//           setTimeout(() => {
//             const drawerBody = document.querySelector(".ant-drawer-body");

//             // Dinamik olarak verilen class ile öğeyi seçiyoruz
//             const openSelect = document.querySelector(`.${className}`);

//             if (openSelect && drawerBody) {
//               // openSelect öğesinin pozisyonunu alıyoruz
//               const openSelectPosition =
//                 openSelect.getBoundingClientRect().top + window.scrollY;

//               // drawerBody'nin pozisyonunu alıyoruz (drawerBody'nin üst kısmı ile ekran arasındaki mesafe)
//               const drawerBodyPosition =
//                 drawerBody.getBoundingClientRect().top + window.scrollY;

//               // drawerBody'nin yüksekliği
//               const drawerBodyHeight = drawerBody.offsetHeight;

//               // openSelect'in drawerBody'ye göre hizalanması için gereken pozisyon
//               const offsetPosition =
//                 openSelectPosition -
//                 drawerBodyPosition -
//                 drawerBodyHeight +
//                 openSelect.offsetHeight;

//               // drawerBody'nin scrollTop değerini, hesapladığımız pozisyona göre ayarlıyoruz
//               drawerBody.scrollTo({
//                 top: offsetPosition - drawerBody.offsetTop, // drawerBody'yi düzgün kaydırmak için offset ekliyoruz
//                 behavior: "smooth", // Animasyonlu kaydırma
//               });
//             }
//           }, 100);

//           // Gecikmeli olarak "select-open" sınıfını toggle ediyoruz
//           setTimeout(() => {
//             document.body.classList.toggle(`${className}-open`, open);
//           }, 500);
//         } else {
//           document.body.classList.remove(`${className}-open`);
//         }
//       }
//     },
//     dropdownStyle: {
//       maxHeight: isMobile ? "160px" : "",
//       overflow: isMobile ? "hidden" : "auto",
//     },
//     virtual: false,
//     getPopupContainer: (triggerNode) => triggerNode.parentNode,
//   };
// };

const selectConfig = (className) => {
  return {
    onDropdownVisibleChange: (open) => {
      if (isMobile) {
        if (open) {
          document.body.classList.toggle("select-open");
        } else {
          document.body.classList.remove("select-open");
        }
      }
    },
    dropdownStyle: {
      maxHeight: isMobile ? "160px" : "auto",
      overflow: isMobile ? "hidden" : "auto",
    },
    virtual: true,
    getPopupContainer: (triggerNode) => triggerNode.parentNode,
  };
};

export default {
  apiUrl,
  maxLength,
  dateFormat,
  useStyle,
  bigCaldateFormat,
  calendardateFormat,
  countryPhoneCode,
  otherAgency,
  MAX_TOTAL_SIZE_MB,
  maxCount,
  chartColor,
  textareaMaxLength,
  emailMaxLength,
  isMobile,
  isTablet,
  selectConfig,
};
